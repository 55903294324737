body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.main-container-page {
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  height: 100vh;
  /* background-color: #f8f8f8; */
  background-color: #fcf3f38a;
}

.left-main-container-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 50%;
}

.left-main-container-logo-page{
  width: 40%;
  height: 20%;
  padding: 25px;
}

.left-main-container-logo-page img {
  width: 100%;
  height: 100%;
  /* background-color: #f8f8f8; */
  /* background-color: red; */
}

.left-main-container-form-page {
  width: 40%;
  height: 35%;
}

.left-main-container-form-page form{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;
}

.left-main-container-form-page form .label-input {
   display: flex;
   flex-direction: column;
}

.left-main-container-form-page form .label-input label{
  padding-bottom: 5px;
  font-family: 'Amandez', sans-serif;
}

.left-main-container-form-page form .label-input input{
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.3s;
}

.left-main-container-form-page form .button-container{
  width: 100%;
  display: flex;
  justify-content: center;
}
.left-main-container-form-page form button{
  width: 30%;
  height: 35px;
  background-color: #1c9fdd;
  color: #fff;
  font-weight: bold;
  font-size: 17px;
  border-radius: 5px;
}

.left-main-container-form-page form button:hover {
  background-color: #147fc3;
}

.right-main-container-page {
  /* overflow: hidden; */
  height: 100%;
  width: 50%;
  /* background-color: yellow; */
  /* padding: 20px; */
  /* position: relative; */
}

.right-main-container-page .logo-image-container{
  width: 100%;
  display: flex;
  justify-content: flex-end;
  height: 10%;
}

.right-main-container-page .right-main-container-page-company-logo{
  width: 20%;
  height: 100%;
}

.right-main-container-page .right-main-container-page-company-logo img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-main-container-page .right-main-container-page-text{
  max-width: 100%;
  text-align: center;
  letter-spacing: 3px;
  font-weight: 600;
  padding: 6% 1% 0% 10%;
  margin-left: 10%;
  margin-right: 10%;
  font-family: 'Calisto MT';
  font-size: 25px;
}

.right-main-container-page .right-image-container{
  width: 100%;
  display: flex;
  justify-content: center;
}

.right-main-container-page .right-image-container .security-container{
  width: 30%;
  height: 40%;
  position: absolute;
  top: -10%;
  left: 1%;
}

.right-main-container-page .right-image-container .security-container img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.right-main-container-page .right-image-container .illustration-container{
   width: 50%;
   height: 50%;
   position: relative;
   margin-top: 10%;
}

.right-main-container-page .right-image-container .illustration-container img{
   width: 100%;
   height: 100%;
   object-fit: cover;
}

.error-message {
  color: red;
}

/* Responsive Styles */

@media (max-width: 600px) {
  .main-container-page {
    flex-direction: column;
  }

  .left-main-container-page {
    width: 100%;
    align-items: center;
    padding-top: 20px;
  }

  .left-main-container-logo-page {
    width: 60%;
  }

  .left-main-container-form-page {
    width: 70%;
    height: auto;
  }

  .left-main-container-form-page form .button-container {
    width: 100%;
  }

  .left-main-container-form-page form button {
    width: 100%;
  }

  .right-main-container-page {
    width: 100%;
    padding: 20px;
  }

  .right-main-container-page .logo-image-container {
    justify-content: center;
  }

  .right-main-container-page .right-main-container-page-company-logo {
    width: 40%;
  }

  .right-main-container-page .right-main-container-page-text {
    width: 90%;
    padding: 5% 0% 1% 0;
    margin-left: 0;
    font-size: 20px;
  }

  .right-main-container-page .right-image-container .security-container {
    width: 40%;
    height: 30%;
    top: -5%;
  }

  .right-main-container-page .right-image-container .illustration-container {
    width: 70%;
    margin-top: 5%;
  }
}