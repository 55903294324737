/* YourComponent.module.css or YourComponent.css */
@keyframes blink {
    0%, 100% {
      opacity: 1;
    }
    25%, 75% {
        opacity: 0.7;
      }
  }
  
  .animate-blink {
    animation: blink 1s infinite;
  }
  
  .animate-blink-secondary {
    animation: blink 2s infinite;
  }